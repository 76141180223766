import React from "react"
import Layout from "../../components/Layout"

const Resettling = () => {
  return (
    <Layout>
      <div className="resettling__picture" />
      <section className="sponsorship__detail">
        <h1 className="sponsorship__detail-title">
          Resettle in Canada as Refugees
        </h1>
        <h3>Determine your eligibility</h3>
        <p>
          To come to Canada as a refugee, you must be referred. The United
          Nations Refugee Agency (UNHCR), a designated referral organization, or
          a private sponsorship group can refer you. You cannot apply directly
          to Immigration, Refugee, and Citizenship Canada. To be referred, you
          must fall into one of these two refugee classes.
        </p>
        <h4>1. Convention refugee abroad class</h4>
        <p>You may be in this class if you:</p>
        <ul className="sponsorship__list">
          <li>are outside your home country</li>
          <li>
            cannot return there due to a well-founded fear of persecution based
            on race, religion, political opinion, nationality, or membership in
            a particular social group. Examples: gender, sexual orientation,
            etc.
          </li>
        </ul>
        <p>Convention refugees can be sponsored by:</p>
        <ul className="sponsorship__list">
          <li>
            the Government of Canada (
            <a
              href="https://www.canada.ca/en/immigration-refugees-citizenship/services/refugees/help-outside-canada/government-assisted-refugee-program.html"
              target="_blank"
              rel="noreferrer"
            >
              government-assisted refugees
            </a>
            ),
          </li>
          <li>
            a group of people or an organization (
            <a
              href="https://www.canada.ca/en/immigration-refugees-citizenship/services/refugees/help-outside-canada/private-sponsorship-program/refugees-information.html"
              target="_blank"
              rel="noreferrer"
            >
              privately sponsored refugees
            </a>
            ), or
          </li>
          <li>
            a mix of both (
            <a
              href="https://www.canada.ca/en/immigration-refugees-citizenship/services/refugees/help-outside-canada/private-sponsorship-program/blended-visa-office-program.html"
              target="_blank"
              rel="noreferrer"
            >
              blended visa office-referred refugees
            </a>
            ).
          </li>
        </ul>
        <p>
          You can also be a Convention refugee if you have the funds needed to
          support yourself and your family after you arrive in Canada. You will
          still need the UNHCR, a referral organization, or a private
          sponsorship group to refer you.
        </p>
        <h4>2. Country of asylum class</h4>
        <p>You may be in this class if you:</p>
        <ul className="sponsorship__list">
          <li>
            are outside your home country, or the country where you normally
            live, and
          </li>
          <li>
            have been seriously affected by civil war or armed conflict, or
          </li>
          <li>have been denied basic human rights on an ongoing basis.</li>
        </ul>
        <p>
          Country of asylum class refugees can be privately sponsored. You can
          also be in this class if you have the funds you need to support
          yourself and your family after you arrive in Canada. You will still
          need the UNHCR, a referral organization, or a private sponsorship
          group to refer you.
        </p>
        <h3>How to apply</h3>
        <p>
          If you are referred, you will need to fill out the application package
          to{" "}
          <a
            href="https://www.canada.ca/en/immigration-refugees-citizenship/services/application/application-forms-guides/applying-convention-refugee-humanitarian-protected-person-abroad.html"
            target="_blank"
            rel="noreferrer"
          >
            apply as a Convention refugee or as a humanitarian-protected person
            abroad
          </a>
          . You will need to pass a{" "}
          <a
            href="https://www.canada.ca/en/immigration-refugees-citizenship/services/application/medical-police/medical-exams.html"
            target="_blank"
            rel="noreferrer"
          >
            medical exam
          </a>{" "}
          and{" "}
          <a
            href="https://www.canada.ca/en/immigration-refugees-citizenship/services/application/medical-police/police-certificates.html"
            target="_blank"
            rel="noreferrer"
          >
            security and criminal check
          </a>
          .
        </p>
        <p>
          Note: The province of Quebec selects its own refugees. Find out about{" "}
          <a
            href="http://www.immigration-quebec.gouv.qc.ca/en/immigrate-settle/refugees-other/refugee-selected/selection.html"
            target="_blank"
            rel="noreferrer"
          >
            Quebec&apos;s procedures for selecting refugees abroad
          </a>
          .
        </p>
        <h3>Who is not eligible?</h3>
        <p>You are not eligible if:</p>
        <ul className="sponsorship__list">
          <li>
            you have another stable option for protection, such as an offer to
            be resettled in another country,
          </li>
          <li>
            you become a citizen of another country, which will then protect
            you,
          </li>
          <li>you choose to return to the country you left, or</li>
          <li>the reasons you left your country no longer exist.</li>
        </ul>
        <h3>FAQ</h3>
        <p>
          Click{" "}
          <a
            href="https://www.cic.gc.ca/english/helpcentre/results-by-topic.asp?top=11"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>{" "}
          for more information from the Canada government website
        </p>
      </section>
    </Layout>
  )
}

export default Resettling
